import { Box, InputAdornment, Stack, Tooltip, Typography } from "@mui/material"
import { If } from "lib/@components/switch"
import { Editable } from "lib/@components/editable"
import { BoundTextField } from "lib/@components/binding/bound-components"
import { convertToMinMaxInteger } from "lib/number"
import { useEffect, useMemo, useRef } from "react"
import noop from "lib/noop"
import { SummaryDataChanged } from "event-definitions"
import { prevent } from "lib/prevent"
import { MinHeightBox } from "lib/@components/minHeightBox"
import { HighlightBox } from "lib/@components/highlightBox"
import { BoundSimpleCombo } from "lib/@components/combos/boundSimpleCombo"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useBoundValue } from "lib/@components/binding/use-bound-value"
import { warningSnackbar } from "lib/snackbar/warning-snackbar"

export const ZERO_U_WORDS = (
    <Typography component="span" variant="body2">
        0U (Zero U) tasks which means the frequency that those tasks should be carried out is to be defined by the user
        based on a site-specific assessment.
    </Typography>
)

export function Frequency({ sx }) {
    const { task } = useBoundContext()

    if (!task?.frequency) return null

    return (
        <MinHeightBox ml={1} className="frequency">
            <Editable pb={0.4} pt={0.4} editor={<FrequencyEditor sx={sx} />}>
                <HighlightBox path="frequency">
                    <Tooltip
                        enterDelay={300}
                        enterNextDelay={300}
                        title={
                            task.frequency.period[0] === "U" ? (
                                ZERO_U_WORDS
                            ) : (
                                <Stack>
                                    <Typography variant="caption" component="div">
                                        Frequency
                                    </Typography>
                                    <Typography variant="body2">
                                        Every {task.frequency.interval}{" "}
                                        {task.frequency.period.pluralize(task.frequency.interval)}
                                    </Typography>
                                </Stack>
                            )
                        }
                    >
                        <Box borderRadius={1} minWidth={"6.5em"} p={0.5} pl={1} pr={1}>
                            <If not truthy={task.frequency.period[0] === "U"}>
                                <strong>Every </strong>
                            </If>
                            <span>
                                {task.frequency.interval}
                                {task.frequency.period[0]}
                            </span>
                        </Box>
                    </Tooltip>
                </HighlightBox>
            </Editable>
        </MinHeightBox>
    )
}

function FrequencyEditor({ cancel = noop, onClick = noop, readOnly, sx }) {
    const me = useRef()
    const time = useRef(0)
    const input = useRef()
    const [frequency, setFrequency] = useBoundValue("frequency.interval", "")
    const [period, setPeriod] = useBoundValue("frequency.period", "")
    const [click, handleBlur] = useMemo(
        () => [
            function click() {
                if (readOnly) return
                time.current = Date.now()
                onClick()
                setTimeout(() => input.current?.focus())
            },
            function handleBlur() {
                if (readOnly) return
                if (!frequency || !+frequency) {
                    setFrequency("0")
                    setPeriod("Unknown")
                }
                if (Date.now() - time.current > 70) {
                    cancel()
                }
            },
        ],
        [readOnly, onClick, frequency, setPeriod, setFrequency, cancel]
    )
    useEffect(() => {
        if (!!frequency && frequency > 0 && (!period || period === "Unknown")) {
            setPeriod("Month")
        }
        if (!frequency || +frequency === 0) setPeriod("Unknown")
    }, [period, frequency, setPeriod])

    useEffect(() => {
        if (frequency > 1200) {
            setFrequency(1200)
            warningSnackbar("Frequency cannot exceed 1200")
        }
    }, [frequency, setFrequency])
    return (
        <Box ref={me} onBlur={handleBlur}>
            <BoundTextField
                variant="outlined"
                label="Frequency"
                data-cy="frequency-input"
                onClick={prevent(noop)}
                fullWidth={false}
                onChanged={SummaryDataChanged.raiseOnce}
                sideEffectsOnBlur
                inputRef={input}
                sx={{ width: 122, m: 0, p: 0, ...sx }}
                InputProps={{
                    readOnly,
                    sx: { pr: 0.2 },
                    endAdornment: !!frequency && +frequency > 0 && (
                        <InputAdornment position="end">
                            <BoundSimpleCombo
                                onChanged={SummaryDataChanged.raiseOnce}
                                aria-label="Period"
                                readOnly={readOnly}
                                options={{
                                    Hour: "Hour",
                                    Day: "Day",
                                    Week: "Week",
                                    Month: "Month",
                                    Year: "Year",
                                }}
                                onClick={click}
                                field="frequency.period"
                            />
                        </InputAdornment>
                    ),
                }}
                transformOutOnBlur={convertToMinMaxInteger(0, 1200)}
                field="frequency.interval"
                size="small"
            />
        </Box>
    )
}
