import { Box } from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import Iconify from "minimals-template/components/Iconify"

export const UOM_TO_INPUT = {
    System: "System Count",
    Nr: "Asset Count",
    "Not specified": "",
    [`m²`]: "Area",
    Metre: "Length",
}

export const UOM_TO_NAME = {
    System: "Number of systems",
    Nr: "Number of assets",
    "Not specified": "",
    [`m²`]: "Area in m²",
    Metre: "Length in m",
}

export const UOM_TO_CAPTION = {
    System: "System",
    Nr: "Asset",
    "Not specified": "",
    [`m²`]: "Area",
    Metre: "Length",
}

export const UOM_TO_DESCRIPTION = {
    System: (
        <ListItemBox>
            <Iconify icon="typcn:times" />
            <Box ml={1}>system</Box>
        </ListItemBox>
    ),
    Nr: (
        <ListItemBox>
            <Iconify icon="typcn:times" />
            <Box ml={1}>asset</Box>
        </ListItemBox>
    ),
    "Not specified": "",
    [`m²`]: (
        <ListItemBox>
            <Iconify icon="typcn:times" />
            <Box ml={1}>m²</Box>
        </ListItemBox>
    ),
    Metre: (
        <ListItemBox>
            <Iconify icon="typcn:times" />
            <Box ml={1}>m</Box>
        </ListItemBox>
    ),
}
