import { Box, Stack, Tooltip, Typography } from "@mui/material"
import { Editable } from "lib/@components/editable"
import { useMemo, useRef } from "react"
import { HighlightBox } from "lib/@components/highlightBox"
import uniqolor from "uniqolor"
import { useSkills } from "routes/@lib/skills"
import { EditSkills } from "routes/schedule/edit-skills"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function SkillSet({ describe, defaultSkillSet, sx }) {
    const { target } = useBoundContext()
    const [skillsIndex] = useSkills()
    const skillSet = skillsIndex[target?.skillSet ?? defaultSkillSet]

    const former = useRef()
    const definition = useMemo(() => {
        former.current = skillSet || former.current
        return former.current
    }, [skillSet])

    return definition ? (
        <Box ml={1} sx={sx} className="skill-set">
            <Editable
                pt={1.4}
                pb={1.4}
                pl={0.1}
                pr={0.1}
                editor={
                    <EditSkills>
                        <Tooltip placement="right" arrow title={definition?.Skilling}>
                            <Box aria-label="Skill set" borderRadius={1} textAlign="center" width={48}>
                                <Box>{definition?.SkillingCode}</Box>
                            </Box>
                        </Tooltip>
                    </EditSkills>
                }
            >
                <HighlightBox path="skillSet">
                    <Tooltip
                        enterDelay={300}
                        enterNextDelay={300}
                        title={
                            <Stack>
                                <Typography variant="caption" component="div">
                                    Skill Required
                                </Typography>
                                <Typography variant="body2">{definition?.Skilling}</Typography>
                            </Stack>
                        }
                    >
                        <Box
                            p={1}
                            minWidth={44}
                            borderRadius={1}
                            bgcolor={`${uniqolor(definition?.Skilling).color}40`}
                            textAlign="center"
                            position="relative"
                        >
                            {describe ? definition.Skilling : definition?.SkillingCode}
                        </Box>
                    </Tooltip>
                </HighlightBox>
            </Editable>
        </Box>
    ) : (
        <Box alignSelf="center" sx={{ opacity: 0.5 }} width={38} textAlign="center">
            {target?.skillSet}
        </Box>
    )
}
