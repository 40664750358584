import { useSkills } from "routes/@lib/skills"
import { BoundPopup } from "lib/@components/combos/boundPopup"
import { SummaryDataChanged } from "event-definitions"

export function EditSkills({ onClick, children }) {
    const [skillSets] = useSkills()
    return (
        <>
            {!!skillSets && (
                <BoundPopup
                    onChanged={SummaryDataChanged.raiseOnce}
                    field="skillSet"
                    label="Skill"
                    options={Object.fromEntries(
                        Object.values(skillSets ?? {}).map(({ CoreSkillingID, SkillingCode, Skilling }) => [
                            CoreSkillingID,
                            `${SkillingCode}: ${Skilling}`,
                        ])
                    )}
                    onClick={onClick}
                >
                    {children}
                </BoundPopup>
            )}
        </>
    )
}
