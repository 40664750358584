import noop from "lib/noop"
import { useRef, useState } from "react"
import { Box, Button, Menu, MenuItem, Stack, Typography } from "@mui/material"
import { prevent } from "lib/prevent"
import Iconify from "minimals-template/components/Iconify"
import { useBoundValue } from "lib/@components/binding/use-bound-value"

export function BoundSimpleCombo({
    field,
    transformValue = (v) => v,
    onClick = noop,
    onChanged = noop,
    readOnly,
    sort = "1",
    label,
    defaultValue,
    options = {},
    size = "small",
    ...props
}) {
    options = Array.isArray(options) ? Object.fromEntries(options.map((v) => [v, v])) : options
    const [value, setValue] = useBoundValue(field, defaultValue)
    const [anchorEl, setAnchorEl] = useState(null)
    const time = useRef(0)
    const open = Boolean(anchorEl)
    return (
        <Stack direction="row" sx={{ userSelect: "none" }} alignItems="center">
            {!!label && (
                <Box mr={1}>
                    <Typography variant="caption">{label}</Typography>
                </Box>
            )}
            <Button
                size={size}
                className={`bind ${field.replaceAll(".", "-")}`}
                disabled={readOnly}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={prevent(handleClick)}
                onMouseDown={prevent(handleClick)}
                endIcon={open ? <Iconify icon="mdi:arrow-drop-up" /> : <Iconify icon="mdi:arrow-down-drop" />}
                {...props}
                sx={{ py: 0.3, px: 1, minHeight: 24, minWidth: 48, ...props.sx }}
            >
                {options[transformValue(value)]}
            </Button>
            <Menu anchorEl={anchorEl} sx={{ zIndex: 5010 }} open={open} onClose={handleClose(value)}>
                {Object.entries(options)
                    .sortBy(sort)
                    .map(([value, label]) => (
                        <MenuItem key={value} size="small" onMouseDown={onClick} onClick={prevent(handleClose(value))}>
                            {label}
                        </MenuItem>
                    ))}
            </Menu>
        </Stack>
    )

    function handleClick(e) {
        time.current = Date.now()
        onClick()
        onChanged(time.current)
        if (open) return
        setAnchorEl(e.currentTarget)
    }

    function handleClose(value) {
        return prevent(function close() {
            onClick()
            setValue(value)
            onChanged(value)
            if (Date.now() - time.current < 100) return
            setAnchorEl(null)
        })
    }
}
