import { Box } from "@mui/material"

export function MinHeightBox({ minHeight = 43, ...props }) {
    return (
        <Box
            className="min-height-box"
            py={1}
            sx={{ minHeight, display: "flex", flexDirection: "row", alignItems: "center", ...props.sx }}
        >
            <Box {...props} />
        </Box>
    )
}
