import noop from "lib/noop"
import { useId } from "lib/@hooks/useId"
import { forwardRef, useMemo, useRef, useState } from "react"
import { Box, FormControl, InputLabel, Menu, MenuItem, OutlinedInput, Portal } from "@mui/material"
import { prevent } from "lib/prevent"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useBoundValue } from "lib/@components/binding/use-bound-value"

function popupContents(children, contentProps) {
    return forwardRef(function Popup(props, ref) {
        return (
            <Box ref={ref} px={1} pt={1.5} width={1} height={40} {...contentProps}>
                {children}
            </Box>
        )
    })
}

export function BoundPopup({
    field,
    children = null,
    label = "",
    onClick = noop,
    onChanged = noop,
    options = {},
    contentProps = {},
    ...props
}) {
    options = Array.isArray(options) ? Object.fromEntries(options.map((v) => [v, v])) : options
    const id = useId()
    const parent = useRef()
    const [value, setValue] = useBoundValue(field)
    const { refresh } = useBoundContext()
    const [anchorEl, setAnchorEl] = useState(null)
    const time = useRef(0)
    const open = Boolean(anchorEl)
    const contents = useMemo(() => popupContents(children, contentProps), [children, contentProps])
    return (
        <FormControl
            className={`${props.className ?? ""} bind ${field.replaceAll(".", "-")}`}
            {...props}
            sx={{ userSelect: "none", ...props.sx }}
            aria-haspopup
            variant="outlined"
            fullWidth
            role="button"
            tabIndex="0"
            onClick={handleClick}
            onKeyDown={handleKeyDown}
        >
            <InputLabel sx={{ mt: !contents ? -1 : 0 }} htmlFor={id}>
                {label}
            </InputLabel>
            <OutlinedInput
                ref={parent}
                id={id}
                value={contents ? "value" : ""}
                label={label}
                inputComponent={contents}
            />
            <Portal>
                <Menu sx={{ zIndex: 5210 }} anchorEl={anchorEl} open={open} onClose={handleClose(value)}>
                    {Object.entries(options)
                        .sortBy("1")
                        .filter(([, l]) => !!l)
                        .map(([value, label]) => (
                            <MenuItem key={value} size="small" onClick={prevent(handleClose(value))}>
                                {label}
                            </MenuItem>
                        ))}
                </Menu>
            </Portal>
        </FormControl>
    )

    function openPopup() {
        setAnchorEl(parent.current)
    }

    function handleClick() {
        openPopup()
    }

    function handleKeyDown(event) {
        if (event.key === "Enter") {
            openPopup()
        }
    }

    function handleClose(value) {
        return prevent(function close() {
            setValue(value)
            onClick()
            onChanged(value)
            refresh()
            if (Date.now() - time.current < 100) return
            setAnchorEl(null)
            setTimeout(() => {
                const focal = document.getElementById(id)
                focal?.focus()
            })
        })
    }
}
