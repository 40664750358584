import React from "react"
import { Box } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export const HighlightBox = React.forwardRef(function HighlightBox({ children, path, ...props }, ref) {
    const { comparisonFunction = () => [false, false], target } = useBoundContext()
    const [localShow, shouldShow] = comparisonFunction({ path, target })
    const theme = useTheme()

    const outlineColor =
        theme.palette.mode !== "light"
            ? `${localShow ? "3px solid" : "1px dashed"} #ffffff50`
            : `${localShow ? "3px solid" : "1px dashed"} #00000050`
    return (
        <Box
            {...props}
            ref={ref}
            sx={{
                borderRadius: shouldShow ? 1 : undefined,
                outline: shouldShow ? outlineColor : undefined,
                ...props.sx,
            }}
        >
            {children}
        </Box>
    )
})
