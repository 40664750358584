import { useRef } from "react"
import { Bound } from "lib/@components/binding/Bound"
import { Box } from "@mui/material"
import { prevent } from "lib/prevent"
import { Editing } from "lib/@components/editable/editing"

export function Editable({ children, editor, onClick, field, size = 1, ...props }) {
    const Type = editor.type
    const ref = useRef(null)

    return (
        <Editing
            field={field}
            editor={
                <Bound onClick={onClick}>
                    <Box
                        ref={ref}
                        minHeight={10}
                        sx={{ ...props.sx, transform: `scale(${size})` }}
                        onClick={prevent(focus)}
                    >
                        <Type onClick={handleClick} {...editor.props} />
                    </Box>
                </Bound>
            }
            display={
                children && (
                    <Box minHeight={10} sx={{ ...props.sx, transform: `scale(${size})` }}>
                        {children}
                    </Box>
                )
            }
        />
    )

    function handleClick(...params) {
        onClick?.(...params)
    }

    function focus() {
        if (!ref.current) return
        const child = ref.current.querySelector("[tabindex]")

        if (child) {
            child?.focus()
        }
    }
}
